import React from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const sre_devops = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description: 'We are looking for a SRE / DevOps. Apply to join our team!',
    },
    keywords: {
      keywords: 'SRE / DevOps,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/6L93cS3lnD0hQB87WIl2bk/ba196e3dc38e1c6987d77f6de4d90963/sre-devops.jpg',
      },
    },
    title: {
      title: 'SRE / DevOps',
    },
  }

  const link = 'https://apply.workable.com/lensesio/j/F270E5F9D3/'

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">SRE / DevOps</h1>
          <p className="meta">Remote (Europe)</p>
          <section className="section section--text">
            <WhoWeAre />
            There are three roles currently open:
            <ul>
              <li>
                SRE/Platform with solid abilities in SaaS and managed services
                (senior)
              </li>
              <li>
                SRE/Platform, preferably with experience in Apache Kafka
                (mid/senior)
              </li>
              <li>SRE/DevOps (mid/senior)</li>
            </ul>
            <h5 className="pt-3">What your day-to-day looks like:</h5>
            <h5>SRE/Platform</h5>
            <p>
              You are focused on providing and supporting services for the
              company, our clients, and users. Obviously, it has to be fast,
              secure, scalable, and robust though we keep these descriptions for
              our presentations. In day-to-day life this means building blocks
              of infrastructure, implementing new functionality, automating,
              orchestrating, provisioning, keeping things up-to-date, and
              maintaining.
            </p>
            <h5>
              SRE/Platform with solid abilities in SaaS and managed services
            </h5>
            <p>
              We want to offer Lenses as a managed service. We have an awesome
              team building our portal and we are looking into the SRE who will
              lead the Lenses deployment architecture and implementation. The
              work has already started but you will play a key role in shaping
              the Lenses cloud. Our software connects to customers’ systems, has
              access to data and secrets. It will be your responsibility to make
              it secure. Words like SoC2 and ISO 270001 should not scare you.
            </p>
            <h5>SRE/DevOps</h5>
            <p>
              You will be supporting our product directly. Although devops is a
              culture, DevOps engineers are valuable members who accelerate
              developers by taking charge of the tough challenges for the
              delivery process like CICD, release automation and packaging. Mind
              you that these challenges are not only tough, but they are
              ongoing. As the product evolves, so does the delivery process.
            </p>
            <h5>Primary Technology Stack</h5>
            <p>The most common parts of our current stack include:</p>
            <ul className="pl-4 ">
              <li>Docker</li>
              <li>Kubernetes</li>
              <li>Terraform</li>
              <li>Jenkins</li>
              <li>Google Cloud</li>
              <li>Amazon AWS</li>
              <li>Bash scripts</li>
              <li>Go language</li>
              <li>Linux servers</li>
              <li>Apache Kafka</li>
              <li>Helm</li>
            </ul>
            <p>
              Of course, we touch way more technologies than just these. Our
              product is developed primarily using the technologies below, so
              part of your DevOps tasks will include interacting with them:
            </p>
            <ul>
              <li>Scala (JVM), sbt</li>
              <li>Javascript (React, Angular), yarn</li>
            </ul>
          </section>
          <section className="section section--text">
            <h5 className="pt-3">What you will bring</h5>
            <p>
              Hopefully, we are going to spend many years working together. It’s
              important that we are compatible:
            </p>
            <ul className="pl-4 f-16">
              <li>
                You love being an SRE and are eager to learn and become better
              </li>
              <li>
                You have some experience in computer science (or similar) and
                distributed systems
              </li>
              <li>
                You are not afraid to ask questions, give and receive feedback
              </li>
              <li>
                You have experience in most of our primary technology stack
              </li>
              <li>
                You have strong problem-solving skills, which for us means not
                only you can solve a problem but also you can explain how you
                did it and why your solution is correct
              </li>
              <li>
                You are security, performance, and best-practice conscious
              </li>
              <li>
                You are able to take responsibilities and own projects
                (implementation or even design-wise)
              </li>
              <li>
                You like working in a team and you are able to push through
                language and cultural barriers to work with engineers all over
                the world
              </li>
              <li>You want to automate everything</li>
              <li>
                No task is beneath or above you. Documentation, support, and
                even little tasks are things we all do often. This applies even
                to our CEO!
              </li>
            </ul>

            <h5 className="pt-3">Passionate about</h5>
            <ul className="pl-4 f-16">
              <li>Technology</li>
              <li>Customer Success</li>
              <li>Trying new tactics and messaging</li>
              <li>Being part of a team</li>
              <li>Sharing success with others</li>
              <li>Inspiring people</li>
            </ul>
          </section>

          <section className="section section--text">
            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default sre_devops
